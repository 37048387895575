/********** Add Your Global CSS Here **********/
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
html,
body,
html a {
  margin-bottom: 0;
  font-family: 'Jost', 'Avenir Next', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

.scrollbar-track-y,
.scrollbar-thumb-y {
  width: 5px !important;
}

.scrollbar-track-x,
.scrollbar-thumb-x {
  height: 5px !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-bottomLeft
> .ant-popover-content
> .ant-popover-arrow:after,
.ant-popover-placement-bottomRight
> .ant-popover-content
> .ant-popover-arrow:after,
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-topLeft
> .ant-popover-content
> .ant-popover-arrow:after,
.ant-popover-placement-topRight
> .ant-popover-content
> .ant-popover-arrow:after {
  left: 0;
  margin-left: -4px;
}

/********** Add Your Global RTL CSS Here **********/
/* Popover */
html[dir='rtl'] .ant-popover {
  text-align: right;
}

/* Ecommerce Card */
html[dir='rtl'] .isoCardInfoForm .ant-input {
  text-align: right;
}

/* Modal */
html[dir='rtl'] .has-success.has-feedback:after,
html[dir='rtl'] .has-warning.has-feedback:after,
html[dir='rtl'] .has-error.has-feedback:after,
html[dir='rtl'] .is-validating.has-feedback:after {
  left: 0;
  right: auto;
}

html[dir='rtl'] .ant-modal-close {
  right: inherit;
  left: 0;
}

html[dir='rtl'] .ant-modal-footer {
  text-align: left;
}

html[dir='rtl'] .ant-modal-footer button + button {
  margin-left: 0;
  margin-right: 8px;
}

html[dir='rtl'] .ant-confirm-body .ant-confirm-content {
  margin-right: 42px;
}

html[dir='rtl'] .ant-btn > .anticon + span,
html[dir='rtl'] .ant-btn > span + .anticon {
  margin-right: 0.5em;
}

html[dir='rtl'] .ant-btn-loading span {
  margin-left: 0;
  margin-right: 0.5em;
}

html[dir='rtl']
.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
  padding-left: 25px;
  padding-right: 29px;
}

html[dir='rtl']
.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline)
.anticon {
  margin-right: -14px;
  margin-left: 0;
}

/* Confirm */
html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-body > .anticon {
  margin-left: 16px;
  margin-right: 0;
  float: right;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns {
  float: left;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns button + button {
  margin-right: 10px;
  margin-left: 0;
}

/* Message */
html[dir='rtl'] .ant-message .anticon {
  margin-left: 8px;
  margin-right: 0;
}

/* Pop Confirm */
html[dir='rtl'] .ant-popover-message-title {
  padding-right: 20px;
  padding-left: 0;
}

html[dir='rtl'] .ant-popover-buttons {
  text-align: left;
}

/* Notification */
html[dir='rtl']
.ant-notification-notice-closable
.ant-notification-notice-message {
  padding-left: 24px;
  padding-right: 0;
}

html[dir='rtl']
.ant-notification-notice-with-icon
.ant-notification-notice-message,
html[dir='rtl']
.ant-notification-notice-with-icon
.ant-notification-notice-description {
  margin-right: 48px;
}

html[dir='rtl'] .ant-notification-notice-close {
  right: auto;
  left: 16px;
}

html[dir='rtl'] .ant-notification-notice-with-icon {
  left: 0;
}

/* Dropzone */
html[dir='rtl'] .dz-hidden-input {
  display: none;
}

/* Hide warning of tinyMCE */
.tox-notifications-container {
  display: none !important;
  visibility: hidden !important;
  z-index: -999999 !important;
}

/* Increase the size of reactour arrows */
#___reactour [data-tour-elem='controls'] > button > svg {
  transform: scale(2);
}

/* Profile logo cropper modal styles */
.antd-img-crop-modal .ant-modal-content .ant-modal-header .ant-modal-title {
  font-family: 'Rubik', sans-serif;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.61px;
  text-align: center;
  color: #111315;
}

.antd-img-crop-modal .ant-modal-content .ant-modal-footer button span {
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: center;
  color: #939393;
}

.preview-modal {
  width: 640px !important;
}

.preview-modal .ant-modal-content {
  width: 640px;
  padding: 32px 20px;
}

.preview-modal .ant-modal-content .ant-modal-body {
  width: 100%;
  text-align: center;
}

.preview-modal .ant-modal-content .ant-modal-body p {
  color: #121415;
  font-family: Rubik, sans-serif;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 17px;
  text-align: center;
  margin: 16px 0;
}
.preview-modal .icon-filter_x {
  position: absolute;
  right: 20px;
  top: 20px;
  color: #4787fe;
  cursor: pointer;
}

.preview-modal .ant-btn {
  border-radius: 3px;
  background-color: #4787fe;
  border: 0;
  outline: 0;
  padding: 9px 18px;
  height: auto;
}
.preview-modal:hover .ant-btn {
  border-radius: 3px;
  background-color: #4787fe;
  border: 0;
  outline: 0;
  padding: 9px 18px;
  height: auto;
}

.preview-modal .ant-btn span {
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.5px;
  color: #ffffff;
}

.preview-modal .modal-content p.item {
  background: #f5f9ff;
  border-radius: 4px;
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 17px;
  text-align: center;
  color: #4787fe;
  margin-bottom: 4px;
  padding: 8px;
}

.guide-tour-preview b {
  color: #131415;
  font-family: Rubik, sans-serif;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0.61px;
  line-height: 20px;
  margin-bottom: 16px;
}
.guide-tour-preview p {
  color: #131415;
  font-family: Rubik, sans-serif;
  font-size: 17px;
  font-weight: normal;
  letter-spacing: 0.61px;
  line-height: 20px;
  margin-top: 16px;
}

.guide-tour-preview [data-tour-elem='controls'] {
  display: none;
}

.preview-tour {
  opacity: 0.4;
}

.ant-select-dropdown {
  margin-top: -30px !important;
}

.ant-select-dropdown .ant-select-dropdown-menu-item {
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.7px;
  line-height: 18px;
  margin-bottom: 0;
  color: #121415;
}

.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: transparent !important;
}

.ant-select-dropdown-menu-item:hover {
  background-color: rgba(245, 249, 255, 1) !important;
  color: #121415;
}

.ant-select-dropdown .ant-select-dropdown-menu-item-selected:hover {
  background-color: #4787fe !important;
  color: #ffffff !important;
}

.ant-select-dropdown .ant-select-dropdown-menu-item-selected {
  background-color: #4787fe !important;
  color: #ffffff !important;
}

.antd-img-crop-modal .ant-modal-content .ant-modal-footer .ant-btn {
  height: 34px;
  margin: 0 16px 0 0;
  border-radius: 3px;
  background-color: #f2f2f2;
  border: none;
}

.antd-img-crop-modal .ant-modal-content .ant-modal-footer .ant-btn-primary {
  background-color: #4787fe;
}

.antd-img-crop-modal
.ant-modal-content
.ant-modal-footer
.ant-btn-primary
> span {
  color: white;
}

.ant-btn,
.anticon {
  display: inline-flex !important;
  align-items: center;
  gap: 5px;
}

.ant-btn.invite-btn {
  background: #60b358;
  border-radius: 4px;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.71;
  padding: 17px 23px;
}

.ant-btn.invite-btn:hover,
.ant-btn.invite-btn:focus {
  background: #60b358;
  color: #ffffff !important;
}

.ant-btn {
  border: none !important;
  box-shadow: none !important;
}

.ant-btn-icon-only {
  vertical-align: middle !important;
}

.collapse-trigger-btn {
  background: #ffffff !important;
  border: 1px solid #dfe6e9 !important;
  box-sizing: border-box;
  border-radius: 4px !important;
  box-shadow: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.custom-dot:before {
  content: ' • ';
}
.custom-dot {
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
}

.loading {
  margin-top:10px!important;
  color: #003c5b;
  font-size: 15px;
  font-weight: 500;
}
.loading:after {
  content: ' .';
  animation: dots 0.8s steps(5, end) infinite;
}
@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: #003c5b;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 #003c5b, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 #003c5b, 0.5em 0 0 #003c5b;
  }
}

.page-header {
  padding: 0 !important;
}
.page-header .ant-page-header-heading-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.35;
  color: #003c5b;
}

.ant-pagination {
  display: flex;
  background-color: #f9fafc;
  margin: 0 !important;
  padding: 10px 20px !important;
  justify-content: flex-end;
}

.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-item {
  background: #ffffff;
  border: 1.5px solid #dfe6e9;
  border-radius: 4px;
  margin: 0 2px !important;
}

.ant-pagination .ant-pagination-item a {
  color: #15344f;
}

.ant-pagination .ant-pagination-item-active {
  border-color: #598ab3;
}

.ant-pagination .ant-pagination-item-active a {
  color: #598ab3;
}

.ant-pagination .ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.ant-pagination-options .ant-select {
  background: #ffffff;
  border: 1px solid #dfe6e9;
  border-radius: 4px;
}

.ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
  background: #ffffff !important;
  border: 1.5px solid #dfe6e9 !important;
}

.ant-pagination.mini .ant-pagination-jump-next .ant-pagination-item-link,
.ant-pagination.mini .ant-pagination-jump-prev .ant-pagination-item-link {
  display: block !important;
}

.custom-table {
  box-shadow: 0 3px 14px rgba(22, 63, 145, 0.12);
  margin-top: 23px;
  border: 1px solid #dfe6e9;
  border-radius: 4px;
}

.ant-table .ant-table-thead > tr > th {
  background-color: #f9fafc;
  color: #598ab3;
  font-weight: 600;
  letter-spacing: 0.8px;
  padding: 10px;
  text-decoration: none;
}

.ant-table th.ant-table-cell::before {
  display: none;
}

.ant-table .ant-table-tbody .ant-table-cell.name-column {
  color: #003c5b;
  font-weight: 500;
  line-height: 1.36;
  text-decoration: underline;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
  cursor: pointer;
}

.ant-table .ant-table-tbody .ant-table-cell.email-column {
  max-width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.36;
  text-decoration: underline;
  color: #448ef7;
  cursor: pointer;
}

.ant-table .ant-table-tbody .ant-table-cell.number-column {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.36;
  color: #003c5b;
}

.custom-filter {
  display: flex;
  gap: 5px;
  border: 1px solid #d2d6dc;
  border-radius: 4px;
}
.custom-filter .title {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.42;
  color: #003c5b;
  align-self: center;
  padding: 4px;
}

.custom-filter .ant-select .ant-select-selector {
  border-width: 0 0 0 1px !important;
  border-radius: 0 4px 4px 0 !important;
  border-color: #d2d6dc !important;
}
.custom-filter .ant-select.ant-select-selection-item {
  font-weight: 500;
  font-size: 14px;
  color: #003c5b;
}

.custom-filter .ant-select-arrow {
  color: #003c5b;
}

.custom-filter
.ant-select:not(.ant-select-disabled):hover
.ant-select-selector {
  border-color: #d2d6dc !important;
  border-right: none !important;
}

.ant-select .ant-select-selector {
  border: none;
  background-color: transparent;
  padding-left: 5px;
  color: #003c5b;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
.ant-select-selector {
  box-shadow: none !important;
  border-right: none;
}

.btn-transparent {
  background: none !important;
}

.header {
  display: flex;
  justify-content: space-between;
}

.center {
  justify-content: center;
}

.ant-spin-lg .ant-spin-dot {
  font-size: 60px !important;
}

.ant-form-item-label > label.ant-form-item-required:before {
  display: none !important;
}

.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot{
  left: 48.5%!important;
  top:49%!important;
}

.ant-form-item-label > label.ant-form-item-required:after {
  display: inline-block !important;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  line-height: 1;
  content: '*' !important;
}

.filter-dropdown {
  background: #ffffff !important;
  box-shadow: 0 3px 14px rgba(22, 63, 145, 0.12) !important;
  border-radius: 10px !important;
}

.filter-dropdown .ant-select-item {
  color: #003c5b;
}

.filter-dropdown
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #003c5b;
  background: none;
}

.hr-line {
  margin: 14px 0;
  border: 0.5px solid #ece9f1;
}

.info-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.info-list .indent {
  margin-right: 100px;
}

.info-item {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.35;
  color: #003c5b;
}

.info-item .subject{
  color: #598ab3;
  margin-bottom: 0;
}

.info-item .note{
  margin-left: 10px;
}

.info-item-head {
  font-weight: 600;
  font-size: 12px;
  line-height: 1.3;
  color: #598ab3;
}

.info-item.overflow-two-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 500px;
}

.info-item.weight-normal {
  font-weight: 500;
}

.info-item.email {
  text-decoration-line: underline;
  color: #448ef7;
  cursor: pointer;
  font-weight: 400;
}

.info-item.frame {
  padding: 2px 10px;
  background: rgba(0, 60, 91, 0.1);
  border-radius: 4px;
  width: max-content;
  display: flex;
  align-items: center;
}

.frame {
  padding: 7px;
  background: rgba(0, 60, 91, 0.1);
  border-radius: 4px;
  width: max-content;
  display: flex!important;
  align-items: center;
  gap: 5px;
  color: #598AB3;
  font-weight: 600;
  font-size: 14px;
}

.info-item.frame.normal {
  background: rgba(96, 179, 88, 0.1);
  color: #60b358;
}

.info-item.frame.more {
  background: rgba(254, 23, 23, 0.1);
  color: #fe1717;
}

.info-item.frame.less {
  background: rgba(48, 159, 251, 0.1);
  color: #309ffb;
}

.info-item.margin {
  margin: 2px 0 20px;
}

.info-item.message {
  background: #eef3f7;
  padding: 16px;
  border-radius: 8px;
}

.info-item.message.align-left {
  margin-right: 240px;
}

.info-item.message.align-right {
  margin-left: 240px;
}

.info-item.message:nth-child(2) {
  margin-top: 10px;
}

.info-item.message:not(:last-child) {
  margin-bottom: 8px;
}

.message-title {
  display: inline-block;
  margin: 0 32px 10px 0;
}

.message-title.overflow-text{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
}

.message-title span {
  color: #598ab3;
  margin-right: 8px;
}



.custom-popover {
  top: 70px !important;
  width: 70vw;
  left: 258px !important;
}
.custom-popover .ant-popover-inner {
  background: #ffffff;
  box-shadow: 0 4px 24px rgba(22, 63, 145, 0.12);
  border-radius: 10px;
}
.custom-popover .ant-popover-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.4;
  color: #003c5b;
}
.custom-popover .ant-popover-arrow {
  display: none;
}
.custom-popover .select-info {
  padding: 4px;
  align-self: center;
}


/********** Add Your Global Print CSS Here **********/

@page {
  margin: 16px;
  size: letter landscape;
}

@media print {
  .main-topbar,
  .ant-layout-footer {
    display: none;
  }

  .isomorphicContent {
    padding-top: 0 !important;
  }

  body {
    -webkit-print-color-adjust: exact !important;
  }

  .page-title {
    display: none;
  }
}
